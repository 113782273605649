<template>
  <layout class="organization-structure os" v-load="loading">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="os__title">Organization structure</h4>
          <router-link :to="{name:'settings',params:{index:11}}" tag="button" class="btn btn-success">
            <i class="fas fa-arrow-left"></i>
            Back to settings
          </router-link>
        </div>
      </div>
      <div ref="os-content" class="os__content mt-3">
        <div @click="showInfo(ceo)" class="os__main os-block">
          <div class="os-block__container">
            {{ getPosition(ceo) }}
          </div>
        </div>
        <div class="os__departments">
          <div class="os__department" v-for="(val ,val_index) in values" :key="'lev1'+val_index">
            <div class="text-center">
              <h5 class="os__department-name">{{val.title}}</h5>
            </div>
            <tree-item
              v-for="(item, index) in val.children"
              :key="index"
              :item="item"
              :class="{
                'os-block_multi': val.children.length > 1,
                'os-block_first': index === 0
              }"
              @onPositionClick="showInfo"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <div v-if="staff" class="modal-block">
      <div class="staff">
        <div class="staff__block d-flex">
          <div :style="{backgroundImage: `url(${getAvatar(employeeInfo.photo)})`}" class="staff__avatar"></div>
          <div class="staff__info">
            <p class="staff__badge badge badge-primary">{{getDepartment(staff)}}</p>
            <p class="staff__name font-weight-bold">{{employeeInfo.name}}</p>
            <p class="staff__name mb-3">{{getPosition(staff)}}</p>
            <p class="staff__link"><a :href="`mailto:${employeeInfo.email}`">{{employeeInfo.email}}</a></p>
            <p class="staff__link"><a :href="`tel:${employeeInfo.phone}`">{{employeeInfo.phone}}</a></p>
          </div>
        </div>
        <a @click="closeInfo" class="staff__close">&times;</a>
        <swiper
          ref="swiperComponentRef"
          class="swiper component mt-4"
          :options="swiperOptions"
          v-if="this.employees.length > 1"
        >
          <swiper-slide class="carousel-slide" v-for="(item, index) in employees" :key="item.id">
            <div
              @click="showStaff(index)"
              class="carousel-slide__image"
              :class="{'carousel-slide__image_active': chooseStaffIndex === index}"
              :style="{backgroundImage: `url(${getAvatar(item.photo)})`}"
            ></div>
          </swiper-slide>
        </swiper>
        <a @click="prevSlide" class="swiper-navigation swiper-navigation_left" v-if="showArrow('prev')">
          <i class="fas fa-angle-left"></i>
        </a>
        <a @click="nextSlide" class="swiper-navigation swiper-navigation_right" v-if="showArrow('next')">
          <i class="fas fa-angle-right"></i>
        </a>
      </div>
      <div @click="closeInfo" class="close"></div>
    </div>
  </layout>
</template>

<script>
import TreeItem from './tree-item.vue';
import Layout from '@/router/layouts/main.vue';

export default {
  components: { Layout, TreeItem },
  data() {
    return {
      loading: true,
      staff: null,
      modalId: null,
      slide: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 24
      },
      chooseStaffIndex: 0,
      employees: [],
      employeeInfo: null,
    }
  },
  watch:{
    chooseStaffIndex:{
      handler(){
        if(this.employees.length > 0){
          this.employeeInfo = this.employees[this.chooseStaffIndex]
        }
      }
    }
  },
  computed:{
    tree() {
      return this.$store.state.orgStruct.tree;
    },
    ceo() {
      return this.tree?.[0];
    },
    children() {
      return this.ceo?.children ?? [];
    },
    departments() {
      return this.$store.state.settings.departments;
    },
    values() {
      return this.departments.map((dep) => {
        return {
          ...dep,
          children: this.children.filter(
            (item) => dep.id === item?.position?.department?.id
          )
        }
      })
    }
  },
  methods: {
    showArrow(arrow){
      if (this.employees.length) {
        if (arrow === 'prev') return this.chooseStaffIndex > 0;
        else return this.employees.length - 1 > this.chooseStaffIndex;
      }
    },
    getAvatar(avatar) {
      return avatar || '../../../assets/images/users/avatar-default.svg';
    },
    getDepartment(item) {
      return item?.position?.department?.title || 'Departmert is not chosen!';
    },
    getPosition(item) {
      return item?.position?.title;
    },
    showInfo(item){
      this.chooseStaffIndex = 0;
      this.employees = item?.position?.employees ?? [];
      if (this.employees.length > 0){
        this.staff = item;
        this.employeeInfo = this.employees?.[0];
        this.modalId = `staff-${item.id}`;
        this.$bvModal.show(this.modalId);
      } else {
        this.$showErrorToast('There is no employee!')
      }
    },
    closeInfo() {
      this.staff = null;
    },
    prevSlide() {
      const swiper = this.$refs.swiperComponentRef.$swiper;
      if (!swiper) {
        return;
      }
      if(this.chooseStaffIndex > 0) {
        swiper.slideTo(--this.chooseStaffIndex, 500)
      }
    },
    nextSlide() {
      const swiper = this.$refs.swiperComponentRef.$swiper;
      if (!swiper) {
        return;
      }
      if(this.chooseStaffIndex + 1 < this.employees.length) {
        swiper.slideTo(this.chooseStaffIndex++, 500)
      }
    },
    showStaff(index) {
      this.chooseStaffIndex = index;
      const swiper = this.$refs.swiperComponentRef.$swiper;
      if (!swiper) {
        return;
      }
      swiper.slideTo(this.chooseStaffIndex, 500);
    },
  },
  async mounted() {
    await this.$store.dispatch('orgStruct/getOrgStructureTree');
    await this.$store.dispatch('settings/getDepartmentsList');
    this.loading = false;
  },
}
</script>
<style lang="scss">
.os__content {
  width: 100%;
  overflow: scroll;
  padding: 20px;
}
.os-block {
  position: relative;

  &_multi {
    &:after {
      content: '';
      position: absolute;
      height: calc(100% + 23px);
      width: 15px;
      border-top: 3px solid transparent;
      border-left: 3px solid #fa8c8c;
      top: 25px;
      left: -15px;
    }

    &:before {
      content: '';
      top: 3px;
      left: -15px;
      width: 15px;
      height: 26px;
      position: absolute;
      border-bottom: 3px solid #fa8c8c;
      border-left: 3px solid #fa8c8c;
      border-bottom-left-radius: 12px;
    }
  }
  &_first {
    &::before {
      display: none;
    }
    &:after {
      border-top-left-radius: 12px;
      border-top: 3px solid #fa8c8c;
    }
  }
  &:nth-last-child(2) {
    &:after {
      height: calc(100% - 10px);
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  &__content {
    padding-left: 15px;
  }
  &__container {
    line-height: 13px;
    padding: 2px 10px;
    height: 53px;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 100%;
    position: relative;
    white-space: normal;
    cursor: pointer;

    &_round {
      border-bottom-left-radius: 4px;
    }
  }
  &__list {
    overflow: hidden;
    padding-top: 20px;
    padding-left: 15px;
    position: relative;
  }
  &__connect-line {
    bottom: 35px;
    left: -15px;
    width: 3px;
    height: 10000vh;
    position: absolute;
    border-left: 3px solid;
  }
  &__angle {
    top: calc(50% - 3px);
    left: -15px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-left: 3px solid;
    border-bottom: 3px solid;
    transform: translateY(-50%);
    border-bottom-left-radius: 12px;
  }
}
.os__main {
  width: 120px;
  margin: auto;
  background-color: #fa8c8c;
  border-radius: 4px;
  &:after {
    border: 3px solid transparent;
    border-right: none;
    border-top: none;
    bottom: 50%;
    position: absolute;
    content: '';
    height: 40px;
    top: 100%;
    width: 3px;
    background-color: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
}
.os__departments {
  width: 100%;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.os__department {
  flex-grow: 1;
  min-width: 170px;
  padding: 0px 20px;
  position: relative;

  &:after {
    content: '';
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20px;
    position: absolute;
    border-top: 3px solid #fa8c8c;
  }

  &:before {
    content: '';
    top: 0px;
    left: 50%;
    width: 3px;
    height: 20px;
    position: absolute;
    transform: translateX(-50%);
    border-left: 3px solid #fa8c8c;
  }

  &:last-child {
    &:after {
      width: calc(50% + 1.5px);
      border-right: 3px solid #fa8c8c;
      border-top-right-radius: 12px;
    }
    &::before {
      display: none;
    }
  }
  &:first-child {
    &:after {
      width: calc(50% + 1.5px);
      right: 0px;
      left: unset;
      border-left: 3px solid #fa8c8c;
      border-top-left-radius: 12px;
    }
    &::before {
      display: none;
    }
  }

  &-name {
    padding: 20px 0px;
    position: relative;
    margin-bottom: 0px;

    &:after {
      content: '';
      left: 50%;
      width: 3px;
      bottom: 0px;
      height: 20px;
      position: absolute;
      transform: translateX(-50%);
      border-left: 3px solid #fa8c8c;
    }
  }
}
.staff {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  width: 500px;
  border-radius: 8px;
  padding: 2rem 2rem;
  z-index: 2;
}
.staff__avatar {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  margin-right: 1rem;
  background-color: #e5e5e5;
}
.staff__name, .staff__link {
  margin: 0;
}
.staff__badge {
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 20px;
}
.staff__close {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 22px;
  color: #BFC9F9;
  cursor: pointer;
}
.carousel-slide, .swiper-slide {
  width: 55px;
  height: 55px;
  cursor: pointer;
}
.carousel-slide__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  background-color: #e5e5e5;
}
.swiper-navigation {
  position: absolute;
  bottom: 45px;
  left: 10px;
  font-size: 22px;
  color: #BFC9F9;
  cursor: pointer;
}
.swiper-navigation_right {
  left: auto;
  right: 10px;
}
.carousel-slide__image_active {
  border: 2px solid #BFC9F9;
}
.modal-block {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.close {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
</style>

<template>
  <div class="os-block">
    <div
      class="os-block__container"
      :class="{ 'os-block__container_round': !item.children.length }"
      :style="{ backgroundColor: item.color }"
      @click="$emit('onPositionClick', item)"
    >
      {{ item?.position?.title ?? '' }}
      <div
        v-if="parentColor"
        class="os-block__angle"
        :style="{ borderColor: parentColor }"
      ></div>
      <div
        v-if="isLast"
        class="os-block__connect-line"
        :style="{ borderColor: parentColor }"
      ></div>
    </div>
    <div
      v-if="item.children"
      class="os-block__list"
    >
      <tree-item
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :parentColor="item.color"
        :isLast="index === item.children.length - 1"
        @onPositionClick="(data) => $emit('onPositionClick', data)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'tree-item',
  props: {
    item: {
      type: Object,
      required: true
    },
    parentColor: {
      type: String,
      required: false,
      default: ''
    },
    isLast: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
